import { version } from 'react';

export const normalize = (key) => key.replaceAll('-', '_');

export const endpoint = (key) => {
  const stage = `${process.env.REACT_APP_STAGE}`;
  const res = key.replaceAll('-', '_').split('_');
  const path = res.shift();
  const resource = res.join('');
  return `/${stage}/${path}/${resource}`;
};

export const compare = (prop) => (a, b) => {
  if (a[prop] < b[prop]) return -1;
  if (a[prop] > b[prop]) return 1;
  return 0;
};

export const orderByName = compare('name');
export const orderByGroup = compare('group');

export const getByGroups = (items) =>
  items.sort(orderByGroup).reduce((acc, cur) => {
    const name = `appcode-${cur.code}`;
    const code = cur.code ? name : null;
    const identity = cur.identity || null;
    const init = { code: code, identity: identity, items: [] };
    acc[cur.group] = acc[cur.group] || init;
    acc[cur.group]['items'].push(cur);
    return acc;
  }, Object.create(null));

export const checkStatus = (id, data, items) =>
  !items.find(
    (item) => !data[item[id]] || data[item[id]]['enabled'] === undefined
  );

export const slugify = (str) => {
  return String(str)
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
};

export const isValidUuid = (uuid) => {
  return /^[0-9A-F]{8}-[0-9A-F]{4}-[1][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
    uuid
  );
};

export const getShowedSections = (appCode) => {
  let show = {
    generalData: true,
    communicationMailbox: true,
    notifications: true,
    iosExtractors: true,
    androidExtractors: true,
    apiAccess: true,
    versions: true,
    authLdap: true,
    authCrue: true,
    authSaml: true,
    authOidc: true,
    authUnad: true,
    platformRoles: true,
    licenseRoles: true,
    general: true,
    service: true,
    academic: true,
    economic: true,
    investigation: true,
    humanResource: true,
    secureTitle: true,
    notificationsTab: true,
    credentials: true,
    config: {
      show: true,
      identifiers: {
        show: true,
        university: true,
        universityShort: true,
        versionRequired: true,
        appleName: true,
        appleId: true,
      },
      colors: {
        show: true,
        primary: true,
        secondary: true,
        tertiary: true,
        secondaryLight: true,
        primaryDark: true,
        secondaryDark: true,
        tertiaryDark: true,
      },
      currency: {
        show: true,
        currency: true,
        currencyLang: true,
      },
      login: {
        show: true,
        loginType: true,
        loginTs: true,
        loginSaaS: true,
        extraPublicAccess: true,
        loginPublicAccess: true,
      },
      publicAccessAuth: {
        show: true,
        aspirantUserName: true,
        over50UserName: true,
        studentUserName: true,
        communityUserName: true,
        guestUserName: true,
        password: true,
      },
      publicAccessConf: {
        show: true,
        phoneCountry: true,
        showCommunityHelp: true,
      },
      payment: {
        show: true,
        url: true,
      },
      twitter: {
        show: true,
        account: true,
      },
      attendance: {
        show: true,
        urlAuth: true,
        urlBackend: true,
      },
      enrollment: {
        show: true,
        showEnrollmentButton: true,
      },
      lastAccessControlPage: {
        show: true,
        showLastAccessControlPageButton: true,
      },
      ts: {
        show: true,
        editParams: true,
        degreeValidationUrl: true,
        teacherPeriods: true,
      },
      order: {
        show: true,
        ordination: true,
      },
      economic: {
        show: true,
        useReport: true,
        useReportProject: true,
      },
      training: {
        show: true,
        useTrainingDownload: true,
        trainingTabs: true,
      },
      absences: {
        show: true,
        useAbsencesDurationInput: true,
      },
      alumni: {
        show: true,
        useAlumniRolFilters: true,
        largeForm: true,
        captchaKey: true,
      },
      languages: {
        show: true,
        languages: true,
      },
      widgets: {
        show: true,
        widgets: true,
      },
      tutors: {
        show: true,
        showTutorPhone: true,
      },
      documentManager: {
        show: true,
        allowDocumentManagement: true,
      },
      virtualCard: {
        show: true,
        useAlternativeVirtualCard: true,
        useEndDateVirtualCard: true,
      },
      cdu: {
        show: true,
        cduBackend: true,
        cduInfo: true,
      },
      rateAndShare: {
        show: true,
        shareAndRate: true,
        rateAndShareGuest: true,
      },
      homePage: {
        show: true,
        homepage: true,
        whatsappUrl: true,
        whatsappUrlActive: true,
        whatsappMessage: true,
        webViewUrl: true,
        webViewUrlActive: true,
      },
      download: {
        show: true,
        certificates: true,
        receipts: true,
        withholdings: true,
        rrhh_cv: true,
        service_sheets: true,
        res_cv: true,
        economic_situation: true,
        published_memoirs: true,
      },
      screens: {
        show: true,
        widgets: true,
        favorites: true,
        fav_config: true,
        notifications: true,
        noti_config: true,
        birthday: true,
      },
      versions: {
        show: true,
        versions: true,
      },
      metrics: {
        show: true,
        metrics: true,
      },
    },
    catalog: {
      show: true,
      actions: {
        show: true,
        buttons: true,
      },
      logs: {
        show: true,
        states: true,
      },
    },
  };

  switch (appCode) {
    case 'APP':
      show['versions'] = false;
      show['config']['versions']['show'] = false;
      show['config']['alumni']['show'] = false;
      break;
    case 'PS':
      show['communicationMailbox'] = false;
      show['notifications'] = false;
      show['iosExtractors'] = false;
      show['androidExtractors'] = false;
      show['authCrue'] = false;
      show['licenseRoles'] = false;
      show['investigation'] = false;
      show['notificationsTab'] = false;
      show['config']['identifiers']['appleName'] = false;
      show['config']['identifiers']['appleId'] = false;
      show['config']['colors']['primaryDark'] = false;
      show['config']['colors']['secondaryDark'] = false;
      show['config']['colors']['tertiaryDark'] = false;
      show['config']['login']['extraPublicAccess'] = false;
      show['config']['login']['loginPublicAccess'] = false;
      show['config']['publicAccessAuth']['show'] = false;
      show['config']['publicAccessConf']['show'] = false;
      show['config']['payment']['show'] = false;
      show['config']['twitter']['show'] = false;
      show['config']['attendance']['show'] = false;
      show['config']['ts']['show'] = false;
      show['config']['economic']['show'] = false;
      show['config']['training']['show'] = false;
      show['config']['absences']['show'] = false;
      show['config']['alumni']['show'] = false;
      show['config']['tutors']['show'] = false;
      show['config']['virtualCard']['show'] = false;
      show['config']['cdu']['show'] = false;
      show['config']['rateAndShare']['show'] = false;
      show['config']['homePage']['show'] = false;
      show['config']['download']['show'] = false;
      show['config']['screens']['show'] = false;
      break;
    case 'ALUMNI':
      show['catalog']['show'] = false;
      show['versions'] = false;
      show['config']['versions']['show'] = false;
      break;
    default:
      break;
  }
  return show;
};
