import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import SelectCache from '../../widgets/selectCache';
import SelectSystem from '../../widgets/selectSystem';
import { getFormValue } from '../../../helpers/formdata';
import { normalize } from '../../../helpers/utils';
import { endpoint } from '../../../helpers/utils';
//import SelectSharedCache from '../../widgets/selectSharedCache';

const ServiceItem = ({ item, data, enabled }) => {
  const [code, setCode] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    setCode(normalize(item.serviceId));
    setUrl(endpoint(item.serviceId));
  }, [item]);

  if (!code) {
    return false;
  }

  return (
    <>
      <Row>
        <Form.Check
          name={`service-${code}-enabled`}
          checked={enabled}
          className="visually-hidden"
          onChange={() => false}
          type="checkbox"
        />
        <Col>
          <Form.Group controlId={`service-${code}-url`}>
            <Form.Label>URL {item.name.toLowerCase()}</Form.Label>
            <Form.Control
              name={`service-${code}-url`}
              defaultValue={getFormValue(data, code, 'url')}
              type="text"
            />
            <Form.Text className="text-muted">AWS {url}</Form.Text>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId={`service-${code}-system`}>
            <Form.Label>Sistema fuente</Form.Label>
            <SelectSystem
              name={`service-${code}-system`}
              value={getFormValue(data, code, 'system')}
              defaultValue="cms"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId={`service-${code}-cache`}>
            <Form.Label>Caché</Form.Label>
            <SelectCache
              name={`service-${code}-cache`}
              value={getFormValue(data, code, 'cache')}
            />
          </Form.Group>
        </Col>
        {/* TODO NO HAY URLS INFORMADAS EN EL PANEL 
        {process.env.REACT_APP_STAGE != 'prod' && (
          <Col md={2}>
            <Form.Group controlId={`service-${code}-shared`}>
              <Form.Label>Caché compartida</Form.Label>
              <SelectSharedCache
                name={`service-${code}-shared`}
                value={getFormValue(data, code, 'shared')}
              />
            </Form.Group>
          </Col>
        )} */}
      </Row>
    </>
  );
};
export default ServiceItem;
