import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectLogin = ({ name, value }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    setLoading(false)
    setData(value)
  }, [value])
  if (loading) {
    return false
  }
  return (
    <Form.Control
      onChange={handleChange}
      value={data}
      name={name}
      as="select"
    >
      <option value="debug">DEBUG</option>
      <option value="ldap">LDAP</option>
      <option value="saml">SAML</option>
      <option value="oidc">OIDC</option>
      <option value="hosted_ui">HOSTED UI</option>
      <option value="oauth">OAUTH</option>
      <option value="jwe_crue">JWE CRUE</option>
      <option value="ts">TÍTULO SEGURO</option>
      <option value="ldap_ts">LDAP - TÍTULO SEGURO</option>
      <option value="saml_ts">SAML - TÍTULO SEGURO</option>
      <option value="oidc_ts">OIDC - TÍTULO SEGURO</option>
      <option value="hosted_ui_ts">HOSTED UI - TÍTULO SEGURO</option>
      <option value="oauth_ts">OAUTH - TÍTULO SEGURO</option>
      <option value="jwe_crue_ts">JWE CRUE - TÍTULO SEGURO</option>
      <option value="unad">UNAD</option>
      <option value="unad_ts">UNAD - TÍTULO SEGURO</option>
    </Form.Control>
  )
}
export default SelectLogin
