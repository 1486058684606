export const whiteListInvestigationApp = [
  {
    utilities_calls_status: {
      url: 'https://prueba3/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/informes/v1/informe/inv_apipr/uxxiinv_apipr/uxxi/inv/apipr/app/v2/convocatorias/estados/',
    },
  },
  {
    utilities_project_status: {
      url: 'https://prueba3/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/informes/v1/informe/inv_apipr/uxxiinv_apipr/uxxi/inv/apipr/app/v2/proyectos/estados/',
    },
  },
];
