export const whiteListAcApp = [
  {
    programs_training_material: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/programas/v1/material_formacion/cursos_integrados/',
    },
  },
  {
    mobility_duration_offered: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/movilidad/v1/duraciones/',
    },
  },
  {
    general_languages: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/movilidad/v1/idiomas/',
    },
  },
  {
    mobility_student_request: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/movilidad/v1/solicitud_out_estudiante/',
    },
  },
  {
    mobility_countries_student_offered: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/movilidad/v1/paises_oferta_out_estudiante/',
    },
  },
  {
    mobility_mobility_types: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/movilidad/v1/tipos_movilidad/',
    },
  },
  {
    mobility_universities_student_offered: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/movilidad/v1/universidades_oferta_out_estudiante/',
    },
  },
  {
    catalog_programs_subjects: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/oferta/academica/v1/programas/',
    },
  },
  {
    employment_employment_companies: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/empleo/v1/empresas/',
    },
  },
  {
    documentmanager_doc_types: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/gestor_documental/v1/tipos_documentos/',
    },
  },
  {
    programs_accessible_centers: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/programas/v1/centros_accesibles/',
    },
  },
  {
    programs_accessible_program: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/programas/v1/programas_accesibles/',
    },
  },
  {
    spacereservations_feature_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/reserva_espacios/v1/caracteristicasaula/',
    },
  },
  {
    spacereservations_element_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/reserva_espacios/v1/elementos/',
    },
  },
  {
    spacereservations_hour_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/reserva_espacios/v1/horas/',
    },
  },
  {
    spacereservations_minute_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/reserva_espacios/v1/minutos/',
    },
  },
  {
    utilities_campus_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/geograficos/v1/campus/',
    },
  },
  {
    utilities_building_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/geograficos/v1/edificios/',
    },
  },
  {
    utilities_impartation_centers: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/programas/v1/centros_imparticion/',
    },
  },
  {
    utilities_offered_plans: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/programas/v1/planes_ofertados/',
    },
  },
  {
    planning_occupations_academic_periods: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/planificacion/docente/v1/periodos_academicos_ocupaciones/',
    },
  },
  {
    planning_occupations_subtypes_studies: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/planificacion/docente/v1/subtipos_estudios_ocupaciones/',
    },
  },
  {
    enrollment_active_period: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/evolucion/matricula/v1/periodo_academico_activo/',
    },
  },
];
