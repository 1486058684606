import { whiteListAcApp } from './app/whiteListAcApp';
import { whiteListEcApp } from './app/whiteListEcApp';
import { whiteListInvestigationApp } from './app/whiteListInvestigationApp';
import { whiteListRrhhApp } from './app/whiteListRrhhApp';
import { whiteListAcPortal } from './portal/whiteListAcPortal';
import { whiteListEcPortal } from './portal/whiteListEcPortal';
import { whiteListRrhhPortal } from './portal/whiteListRrhhPortal';

const PORTAL = 'Portal de servicios';
const APP = 'Middleware UXXI-APP';

const whiteListMap = {
  [PORTAL]: {
    ac: whiteListAcPortal,
    rrhh: whiteListRrhhPortal,
    ec: whiteListEcPortal,
  },
  [APP]: {
    ac: whiteListAcApp,
    rrhh: whiteListRrhhApp,
    ec: whiteListEcApp,
    investigation: whiteListInvestigationApp,
  },
};

export const isDataInWhiteList = (code, module) => {
  const list = whiteListMap[process.env.REACT_APP_NAME]?.[module] || [];

  return list.some((item) => Object.keys(item).includes(code)) || false;
};
