import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigLastAccessControlPage = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Página de control último acceso</h2>
      <Row>
        {showSections['showLastAccessControlPageButton'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-showLastAccessControlPageButton`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'showLastAccessControlPageButton')}
                name={`configApp-${code}-showLastAccessControlPageButton`}
                type="checkbox"
                label="Habilitar página de control último acceso"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigLastAccessControlPage
