export const whiteListRrhhPortal = [
  {
    personaldata_street_types: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/tg_generales/v1/lv_tipos_via/',
    },
  },
  {
    personaldata_gender_list: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/tg_generales/v1/lv_sexo/',
    },
  },
  {
    personaldata_country_list: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/tg_generales/v1/lv_pais/',
    },
  },
  {
    personaldata_civil_status: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/tg_generales/v1/lv_estado_civil/',
    },
  },
  {
    merits_merit_type: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/meritos/v1/lv_tipo_merito/',
    },
  },
  {
    merits_merit_status: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/meritos/v1/lv_estado_merito/',
    },
  },
  {
    license_license_country: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/permisos_licencias/v3/lv_pais/',
    },
  },
  {
    license_license_body: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/permisos_licencias/v3/lv_organismos/',
    },
  },
  {
    license_consultable_absences: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/permisos_licencias/v3/lv_tipos_ausencia_cons/',
    },
  },
  {
    license_absences_types: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/permisos_licencias/v3/lv_tipos_ausencia/',
    },
  },
  {
    holidays_holiday_types: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/tg_generales/v1/lv_tipo_festivo/',
    },
  },
  {
    holidays_calendar_types: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/rrhh_apipr/uxxirrhh_apipr/uxxi/rrhh/apipr/permisos_licencias/v3/lv_tipos_calendario/',
    },
  },
];
