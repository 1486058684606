export const whiteListAcPortal = [
  {
    utilities_campus_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/geograficos/v1/campus/',
    },
  },
  {
    utilities_recent_feed: {
      url: 'https://www.nebrija.com/medios/actualidadnebrija/feed/',
    },
  },
  {
    utilities_centers_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/alumno/v1/traslados/centros/',
    },
  },
  {
    transfers_transfers_types: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/alumno/v1/traslados/tipos/',
    },
  },
  {
    transfers_transfers_permissions: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/alumno/v1/traslados/permisos/',
    },
  },
  {
    transfers_transferables_universities: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/alumno/v1/traslados/universidades/',
    },
  },
  {
    transfers_transferables_studies: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/alumno/v1/traslados/estudios/',
    },
  },
  {
    transfers_active_university: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/general/v1/universidad_activa/',
    },
  },
  {
    teaching_center_departments: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/general/v1/departamentos/',
    },
  },
  {
    teaching_academic_periods: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/docencia/v1/periodo_academico/',
    },
  },
  {
    proceedings_view_permissions: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/actas/v1/actas/permisos_portal/',
    },
  },
  {
    proceedings_academic_periods: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/general/v1/periodos_academicos/',
    },
  },
  {
    preregistrations_study_programs: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/preinscripcion/v1/estudios_propios/programas/',
    },
  },
  {
    preregistrations_period_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/preinscripcion/v1/estudios_propios/programas/periodos/',
    },
  },
  {
    preregistrations_course_types: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/preinscripcion/v1/estudios_propios/programas/tipos_curso/',
    },
  },
  {
    preregistrations_campus_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/preinscripcion/v1/estudios_propios/programas/campus/',
    },
  },
  {
    planning_academicperiods_enrollment: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/planificacion/docente/v1/periodos_academicos_matriculas/',
    },
  },
  {
    personalfile_file_permissions: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/alumno/v1/expedientes/permisos/',
    },
  },
  {
    personaldata_validate_document: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/v1/validacion/formato_documento',
    },
  },
  {
    personaldata_street_types: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/geograficos/v1/tipos_via/',
    },
  },
  {
    personaldata_region_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/geograficos/v1/comarcas/',
    },
  },
  {
    personaldata_province_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/geograficos/v1/provincias/',
    },
  },
  {
    personaldata_municipality_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/geograficos/v1/municipios/',
    },
  },
  {
    personaldata_military_notebook: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/general/v1/situacionlibretamilitar/',
    },
  },
  {
    personaldata_health_company: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/general/v1/empresasalud/',
    },
  },
  {
    personaldata_ethnicity_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/general/v1/etnias/',
    },
  },
  {
    personaldata_document_types: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/general/v1/tipos_documento/',
    },
  },
  {
    personaldata_disability_types: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/general/v1/tiposdiscapacidad/',
    },
  },
  {
    personaldata_country_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/geograficos/v1/paises/',
    },
  },
  {
    documentmanager_doc_types: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/gestor_documental/v1/tipos_documentos/',
    },
  },
  {
    creditrecognition_ratings_list: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/alumno/v1/reconocimiento_creditos/calificaciones/',
    },
  },
  {
    catalog_programs_subjects: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/oferta/academica/v1/programas/',
    },
  },
  {
    bankaccount_holder_address: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/v1/validacion/requiere_domicilio_titular_cba',
    },
  },
  {
    bankaccount_account_swift: {
      url: 'https://appuxxiappdes.uxxi.net/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/persona/v1/validacion/requiere_swift_cba',
    },
  },
];
