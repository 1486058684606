import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectSharedCache = ({ name, value }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(false)
  }, [value])
  if (loading) {
    return false
  }
  return (
    <Form.Check
      defaultChecked={value}
      name={name}
      type="checkbox"
    />
  )
}
export default SelectSharedCache
