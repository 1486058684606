import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import SelectCache from '../../widgets/selectCache';
import SelectSystem from '../../widgets/selectSystem';
import { getFormValue } from '../../../helpers/formdata';
import { normalize } from '../../../helpers/utils';
import { endpoint } from '../../../helpers/utils';
import SelectSharedCache from '../../widgets/selectSharedCache';
import { isDataInWhiteList } from '../../../helpers/whiteList/utils';

const HumanResourceItem = ({ item, data, enabled }) => {
  const [code, setCode] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    setCode(normalize(item.humanresourceId));
    setUrl(endpoint(item.humanresourceId));
  }, [item]);

  if (!code) {
    return false;
  }
  return (
    <>
      <Row>
        <Form.Check
          name={`humanresource-${code}-enabled`}
          checked={enabled}
          className="visually-hidden"
          onChange={() => false}
          type="checkbox"
        />
        <Col>
          <Form.Group controlId={`humanresource-${code}-url`}>
            <Form.Label>URL {item.name.toLowerCase()}</Form.Label>
            <Form.Control
              name={`humanresource-${code}-url`}
              defaultValue={getFormValue(data, code, 'url')}
              type="text"
            />
            <Form.Text className="text-muted">AWS {url}</Form.Text>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId={`humanresource-${code}-system`}>
            <Form.Label>Sistema fuente</Form.Label>
            <SelectSystem
              name={`humanresource-${code}-system`}
              value={getFormValue(data, code, 'system')}
              defaultValue="humanresource"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId={`humanresource-${code}-cache`}>
            <Form.Label>Caché</Form.Label>
            <SelectCache
              name={`humanresource-${code}-cache`}
              value={getFormValue(data, code, 'cache')}
            />
          </Form.Group>
        </Col>
        {isDataInWhiteList(code, 'rrhh') && (
            <Col md={2}>
              <Form.Group controlId={`humanresource-${code}-shared`}>
                <Form.Label>Caché compartida</Form.Label>
                <SelectSharedCache
                  name={`humanresource-${code}-shared`}
                  value={getFormValue(data, code, 'shared')}
                />
              </Form.Group>
            </Col>
          )}
      </Row>
    </>
  );
};
export default HumanResourceItem;
