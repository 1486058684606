import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'

const CatalogActions = ({ code, data, showSections }) => {
  return (
    <div className='margin-bottom'>
      <Row>
        {showSections['buttons'] && (
          <Col sm={12}>
            {/* <Button
              className='float-end margin-bottom'
              variant='catalog'
              name='btn-scheduledCatalogUpload'
              value='scheduledCatalogUpload'
              type='submit'
              style={{marginLeft: '10px'}}
            >
              Programar carga
            </Button> */}
            <Button
              className='float-end'
              variant='catalog'
              name='btn-catalogUpload'
              value='catalogUpload'
              type='submit'
            >
              Cargar ahora
            </Button>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default CatalogActions
