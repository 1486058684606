export const whiteListEcApp = [
  {
    commissions_status_commissions: {
      url: 'https://prueba3/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/informes/v1/informe/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/comision/v3/estados/',
    },
  },
  {
    requests_request_types: {
      url: 'https://prueba3/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/informes/v1/informe/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/solicitud/v2/tipos/',
    },
  },
  {
    requests_request_status: {
      url: 'https://prueba3/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/informes/v1/informe/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/solicitud/v2/estados/',
    },
  },
  {
    commissions_countries_commissions: {
      url: 'https://prueba3/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/informes/v1/informe/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/comision/v3/paises/',
    },
  },
  {
    commissions_location_commissions: {
      url: 'https://prueba3/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/informes/v1/informe/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/comision/v3/localidades_provincias/',
    },
  },
  {
    commissions_location_default: {
      url: 'https://prueba3/ac_apipr/uxxiac_apipr/uxxi/ac/apipr/informes/v1/informe/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/comision/v3/localidades_propuestas/origen_defecto/',
    },
  },
];
