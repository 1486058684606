import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const SelectVersion = ({ name, value, versions }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleChange = (e) => {
    setData(Array.from(e.target.selectedOptions, (option) => option.value));
  };

  useEffect(() => {
    setLoading(false);
    setData(value);
  }, [value]);

  if (loading) {
    return false;
  }

  return (
    <Form.Control onChange={handleChange} value={data} name={name} as="select">
      {versions.map((version, index) => (
        <option key={index} value={version}>
          {version}
        </option>
      ))}
    </Form.Control>
  );
};
export default SelectVersion;
