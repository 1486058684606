import React from 'react';
import { Form, Card } from 'react-bootstrap';
import SelectVersion from '../widgets/selectVersion';
import { getFormValue } from '../../helpers/formdata';

const ConfigVersions = ({ code, data, versions, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Versionado</h2>

      {showSections['versions'] && versions && (
        <>
          <Form.Group controlId={`configApp-${code}-current`}>
            <Form.Label>Versión Portal</Form.Label>
            <SelectVersion
              name={`configApp-${code}-current`}
              value={getFormValue(data, code, 'current')}
              versions={versions}
            ></SelectVersion>
          </Form.Group>
        </>
      )}
    </div>
  );
};

export default ConfigVersions;
