export const whiteListEcPortal = [
  {
    utilities_road_types: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/geografico/v1/tipos_via/',
    },
  },
  {
    utilities_country_list: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/comision/v3/paises/',
    },
  },
  {
    personaldata_road_types: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/geografico/v1/tipos_via/',
    },
  },
  {
    personaldata_provider_list: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/persona/v1/terceros/',
    },
  },
  {
    personaldata_delegation_list: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/persona/v1/delegaciones/',
    },
  },
  {
    personaldata_country_list: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/comision/v3/paises/',
    },
  },
  {
    commissions_status_commissions: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/comision/v3/estados/',
    },
  },
  {
    bankaccount_foreign_codes: {
      url: 'https://app-uxxiappstage-lp.uxxi.net/ec_apipr/uxxiec_apipr/uxxi/ec/apipr/persona/v1/tipos_cod_cta_extranjera/',
    },
  },
];
